@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

@import '../../assets/styles/utils';

.datepicker {
  position: relative;

  &__placeholder {
    color: #000;
    font-size: 13.33px;
    font-weight: var(--font-bold);
  }

  &__label {
    cursor: pointer;
    display: block;
    font-size: var(--font-base);
    margin-bottom: 5px;
  }

  &__dropdown {
    background-color: var(--color-white);
    border-radius: 4px;
    filter: var(--drop-shadow);
    left: 50%;
    padding: 16px;
    position: absolute;
    top: 64px;
    transform: translate(-50%, 0%);
    z-index: 1;

    @include media-breakpoint-down('sm') {
      width: 300px !important;
    }
  }

  &__input {
    border: 1px solid var(--color-gray-45);
    border-radius: 8px;
    color: var(--color-black);
    font-size: var(--font-base);
    font-weight: var(--font-bold);
    max-height: 38px;
    padding: 12px;
    text-align: left;
    width: 100%;
  }

  &__input-fake {
    position: relative;
  }

  &__clear {
    color: var(--color-gray-400);
    position: absolute;
    right: 42px;
    top: 11px;

    &:hover {
      display: block;
    }
  }

  &__icon {
    color: var(--color-gray-300);
  }
}

.react-calendar {
  --now: var(--color-ob-green);
  --selected: var(--color-blue-100);

  background: var(--color-white);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  max-width: 100%;
  width: 350px;

  &--doubleView {
    width: 700px;

    .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;

      > * {
        margin: 0.5em;
        width: 50%;
      }
    }
  }

  &,
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  button {
    border: 0;
    margin: 0;
    outline: none;

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;

    button {
      background: none;
      min-width: 44px;

      &:disabled {
        background-color: rgb(240 240 240);
      }

      &:enabled {
        &:hover,
        &:focus {
          background-color: rgb(230 230 230);
        }
      }
    }
  }

  &__month-view {
    &__weekdays {
      font-size: 0.75em;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;

      &__weekday {
        padding: 0.5em;

        abbr {
          text-decoration: none;
        }
      }
    }

    &__weekNumbers {
      .react-calendar__tile {
        align-items: center;
        display: flex;
        font-size: 0.75em;
        font-weight: bold;
        justify-content: center;
      }
    }

    &__days {
      &__day {
        &--weekend {
          color: rgb(209 0 0);
        }

        &--neighboringMonth {
          color: rgb(117 117 117);
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      padding: 2em 0.5em;
    }
  }

  &__tile {
    background: none;
    line-height: 16px;
    max-width: 100%;

    // padding: 10px 6.6667px;
    padding: 8px 0;
    text-align: center;

    &:disabled {
      background-color: rgb(240 240 240);
    }

    &:enabled {
      &:hover,
      &:focus {
        background-color: rgb(230 230 230);
      }
    }

    &--now {
      background: var(--now);
      color: var(--color-white);

      &:enabled {
        &:hover,
        &:focus {
          background: var(--now);
        }
      }
    }

    &--hasActive {
      background: var(--selected);

      &:enabled {
        &:hover,
        &:focus {
          background: var(--selected);
        }
      }
    }

    &--active {
      background: var(--selected);
      color: var(--color-white);

      &:enabled {
        &:hover,
        &:focus {
          background: var(--selected);
        }
      }
    }
  }

  &--selectRange {
    .react-calendar__tile {
      &--hover {
        background-color: rgb(230 230 230);
      }
    }
  }
}

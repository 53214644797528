@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

@import '../../assets/styles/utils';

.card {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-25);
  border-radius: 8px;
  padding: 24px;

  &__header {
    font-size: var(--font-xl);
    font-weight: var(--font-bold);

    @include media-breakpoint-down('sm') {
      font-size: var(--font-base);
    }
  }

  &__title {
    margin: 0 0 5px;
  }

  &__label {
    color: var(--color-gray-400);
    font-size: var(--font-base);
  }
}

@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins/media-breakpoint';

.weather-dropdown {
  &__container {
    color: var(--color-ob-green);
    font-weight: var(--font-bold);
    padding: 8px 0;
    width: 100%;
  }
}

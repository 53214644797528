@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.notes-create {
  background-color: var(--color-white);
  min-height: calc(100vh - 87px);
  padding: 24px;

  @include media-breakpoint-down('md') {
    background-color: inherit;
    padding: 0;
  }

  &__title {
    font-size: var(--font-xl);
    font-weight: var(--font-bold);
    margin-bottom: 24px;
  }

  &__error {
    color: var(--color-red-100);
    font-size: var(--font-sm);
    margin-top: 5px;
  }
}

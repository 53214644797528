@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.password-recovery {
  height: 100vh;

  .logo {
    color: var(--color-ob-green-dark);
    font-size: var(--font-2xl);
    font-weight: 800;
  }
}

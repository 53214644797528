@import '../../mixins/media-breakpoint';

// Rows
.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.grid-rows-none {
  grid-template-rows: none;
}

// Grid Row Size
.row-auto {
  grid-row: auto;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-4 {
  grid-row: span 4 / span 4;
}

.row-span-5 {
  grid-row: span 5 / span 5;
}

.row-span-6 {
  grid-row: span 6 / span 6;
}

.row-span-full {
  grid-row: 1 / -1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-start-7 {
  grid-row-start: 7;
}

.row-start-auto {
  grid-row-start: auto;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-end-6 {
  grid-row-end: 6;
}

.row-end-7 {
  grid-row-end: 7;
}

.row-end-auto {
  grid-row-end: auto;
}

@each $key, $breakpoint in $grid-breakpoints {
  // Rows
  .#{$key}\:grid-rows-1 {
    @include media-breakpoint-down($key) {
      grid-template-rows: repeat(1, minmax(0, 1fr));
    }
  }
  .#{$key}\:grid-rows-2 {
    @include media-breakpoint-down($key) {
      grid-template-rows: repeat(2, minmax(0, 1fr));
    }
  }
  .#{$key}\:grid-rows-3 {
    @include media-breakpoint-down($key) {
      grid-template-rows: repeat(3, minmax(0, 1fr));
    }
  }
  .#{$key}\:grid-rows-4 {
    @include media-breakpoint-down($key) {
      grid-template-rows: repeat(4, minmax(0, 1fr));
    }
  }
  .#{$key}\:grid-rows-5 {
    @include media-breakpoint-down($key) {
      grid-template-rows: repeat(5, minmax(0, 1fr));
    }
  }
  .#{$key}\:grid-rows-6 {
    @include media-breakpoint-down($key) {
      grid-template-rows: repeat(6, minmax(0, 1fr));
    }
  }
  .#{$key}\:grid-rows-none {
    @include media-breakpoint-down($key) {
      grid-template-rows: none;
    }
  }

  // Grid Row Size
  .#{$key}\:row-auto {
    @include media-breakpoint-down($key) {
      grid-row: auto;
    }
  }
  .#{$key}\:row-span-1 {
    @include media-breakpoint-down($key) {
      grid-row: span 1 / span 1;
    }
  }
  .#{$key}\:row-span-2 {
    @include media-breakpoint-down($key) {
      grid-row: span 2 / span 2;
    }
  }
  .#{$key}\:row-span-3 {
    @include media-breakpoint-down($key) {
      grid-row: span 3 / span 3;
    }
  }
  .#{$key}\:row-span-4 {
    @include media-breakpoint-down($key) {
      grid-row: span 4 / span 4;
    }
  }
  .#{$key}\:row-span-5 {
    @include media-breakpoint-down($key) {
      grid-row: span 5 / span 5;
    }
  }
  .#{$key}\:row-span-6 {
    @include media-breakpoint-down($key) {
      grid-row: span 6 / span 6;
    }
  }
  .#{$key}\:row-span-full {
    @include media-breakpoint-down($key) {
      grid-row: 1 / -1;
    }
  }
  .#{$key}\:row-start-1 {
    @include media-breakpoint-down($key) {
      grid-row-start: 1;
    }
  }
  .#{$key}\:row-start-2 {
    @include media-breakpoint-down($key) {
      grid-row-start: 2;
    }
  }
  .#{$key}\:row-start-3 {
    @include media-breakpoint-down($key) {
      grid-row-start: 3;
    }
  }
  .#{$key}\:row-start-4 {
    @include media-breakpoint-down($key) {
      grid-row-start: 4;
    }
  }
  .#{$key}\:row-start-5 {
    @include media-breakpoint-down($key) {
      grid-row-start: 5;
    }
  }
  .#{$key}\:row-start-6 {
    @include media-breakpoint-down($key) {
      grid-row-start: 6;
    }
  }
  .#{$key}\:row-start-7 {
    @include media-breakpoint-down($key) {
      grid-row-start: 7;
    }
  }
  .#{$key}\:row-start-auto {
    @include media-breakpoint-down($key) {
      grid-row-start: auto;
    }
  }
  .#{$key}\:row-end-1 {
    @include media-breakpoint-down($key) {
      grid-row-end: 1;
    }
  }
  .#{$key}\:row-end-2 {
    @include media-breakpoint-down($key) {
      grid-row-end: 2;
    }
  }
  .#{$key}\:row-end-3 {
    @include media-breakpoint-down($key) {
      grid-row-end: 3;
    }
  }
  .#{$key}\:row-end-4 {
    @include media-breakpoint-down($key) {
      grid-row-end: 4;
    }
  }
  .#{$key}\:row-end-5 {
    @include media-breakpoint-down($key) {
      grid-row-end: 5;
    }
  }
  .#{$key}\:row-end-6 {
    @include media-breakpoint-down($key) {
      grid-row-end: 6;
    }
  }
  .#{$key}\:row-end-7 {
    @include media-breakpoint-down($key) {
      grid-row-end: 7;
    }
  }
  .#{$key}\:row-end-auto {
    @include media-breakpoint-down($key) {
      grid-row-end: auto;
    }
  }
}

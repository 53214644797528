@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.footer {
  background-color: var(--color-white);
  border: 1.35px solid var(--color-gray-50);
  border-radius: 24px 24px 0 0;
  bottom: 0;
  padding: 16px 0 12px;
  position: fixed;
  width: 100%;
  z-index: 1;

  .nav {
    .nav-icon {
      fill: var(--color-gray-85);
    }

    &__label {
      bottom: 0;
      display: grid;
      font-size: var(--font-sm);
      height: 30px;
      margin-bottom: 4px;
      position: relative;
    }

    &__item {
      color: var(--color-gray-85);
      font-size: var(--font-xs);
      line-height: 15px;
      position: relative;
      text-align: center;

      .active {
        color: var(--color-ob-green);
        font-weight: var(--font-bold);

        .nav__label {
          &::after {
            background: var(--color-ob-green);
            border-radius: 12px;
            content: ' ';
            height: 35px;
            left: 50%;
            margin: auto;
            position: absolute;
            transform: translate(-50%, 105%) rotate(45deg);
            width: 35px;
          }
        }

        .nav-icon {
          fill: var(--color-ob-green);
        }
      }
    }
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

@import '../../assets/styles/utils';

.alert-parameters {
  margin-left: 40px;
  margin-right: 20px;

  @include media-breakpoint-down('sm') {
    margin: 0;
  }

  .alert-item {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-25);
    border-radius: 8px;

    &__title {
      font-size: var(--font-lg);
      font-weight: var(--font-bold);
      padding: 24px;
      text-align: left;
    }

    &__switch {
      padding: 24px;
      text-align: right;
    }

    &__dropdown {
      padding: 24px;
    }
  }
}

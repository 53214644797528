.widget {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-25);
  border-radius: 8px;
  padding: 24px 23px;

  &--np {
    padding: inherit;
  }

  &__title {
    font-size: var(--font-xl);
    font-weight: var(--font-bold);
    margin-bottom: 40px;
  }
}

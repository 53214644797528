@import '../../mixins/media-breakpoint';

// Import cols
@import './cols';

// Import rows
@import './rows';

.grid {
  display: grid;
}

// Gaps
@each $keyGap, $space in $spacings {
  .gap-#{$keyGap} {
    gap: #{$space};
  }

  @each $keyBreak, $breakpoint in $grid-breakpoints {
    .#{$keyBreak}\:gap-#{$keyGap} {
      @include media-breakpoint-down($keyBreak) {
        gap: #{$space} !important;
      }
    }
  }
}

// Masonary
.grid-masonary {
  // display: box; /* Not needed if autoprefixing */
  // display: flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
}

.grid-masonary__item {
  background-clip: padding-box;
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.empty {
  &__icon {
    color: #cbd5e1;
  }

  &__title {
    font-size: var(--font-xl);
    font-weight: var(--font-bold);
    text-align: center;
  }

  &__message {
    font-size: var(--font-base);
    text-align: center;
  }
}

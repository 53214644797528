@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

@import 'leaflet/dist/leaflet.css';

.shadepanel-detail {
  &__subheader {
    margin: 0 -40px;
  }

  &__title {
    font-size: var(--font-xl);
    font-weight: var(--font-bold);
    margin-bottom: 24px;
  }

  &__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    height: 350px;
    position: relative;
    width: 100%;

    &--no-image {
      background-color: var(--color-gray-25);
      font-size: var(--font-lg);
      font-weight: var(--font-bold);
    }

    .image-date {
      background-color: rgb(0 0 0 / 50%);
      border-radius: 4px;
      color: var(--color-white);
      font-size: var(--font-base);
      left: 5px;
      padding: 0.125rem 0.5rem;
      position: absolute;
      top: 3px;
    }
  }

  &__refresh {
    bottom: 3px;
    left: 3px;
    position: absolute;
  }

  &__widget {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-25);
    border-radius: 8px;
    padding: 24px;
  }
}

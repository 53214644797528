@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.file {
  background-color: var(--color-white);
  border: 2px solid var(--color-gray-25);
  border-radius: 8px;
  padding: 8px 28px;

  &__new {
    color: var(--color-blue-100);
    font-size: var(--font-base);
    font-weight: var(--font-bold);
  }

  &__name {
    font-size: var(--font-base);
  }

  &__delete {
    color: var(--color-red-100);
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

@import '../../assets/styles/utils';

.modal-overlay {
  background-color: rgb(0 0 0 / 50%);
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1040;

  .modal-wrapper {
    position: relative;
    width: auto;
    z-index: 1050;
  }
}

.modal {
  background-color: var(--color-white);
  border-radius: 24px;
  display: relative;
  height: 100%;
  margin: 24px;
  padding: 16px 24px;

  @include media-breakpoint-down('sm') {
    width: 320px !important;
  }

  &__title {
    font-size: var(--font-lg);
    font-weight: var(--font-bold);
  }

  &__close {
    border: 2px solid var(--color-gray-25);
    border-radius: 28px;
    height: 28px;
    width: 28px;
  }

  &__container {
    margin: 16px 0;
    max-height: calc(100vh - 140px);
    overflow-y: auto;
    padding: 16px 8px;
  }
}

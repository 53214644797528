@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.textarea-control {
  &--focused {
    // label {
    //   color: var(--color-ob-green);
    // }
  }

  &--error {
    label {
      color: var(--color-red-100);
    }
  }

  label {
    cursor: pointer;
    display: block;
    font-size: var(--font-base);
    margin-bottom: 5px;
  }

  &__fake {
    position: relative;
  }

  &__icon {
    color: var(--color-red-100);
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: 10;
  }

  &__input {
    border: 1px solid var(--color-gray-45);
    border-radius: 8px;
    color: var(--color-black);
    font-size: var(--font-base);
    min-height: 150px;
    padding: 12px;
    position: relative;
    width: 100%;

    .input-control--error & {
      border: 2px solid var(--color-red-100 !important);
      margin: -1px;
    }

    &:hover {
      border: 2px solid var(--color-gray-45);
      margin: -1px;
    }

    &:focus,
    &:focus-visible {
      border: 2px solid var(--color-ob-green);
      margin: -1px;
      outline: 0;
    }
  }

  &__hint {
    color: var(--color-dark-gray);
    font-size: var(--font-sm);
    margin-top: 5px;

    &--error {
      color: var(--color-red-100);
    }
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

@import '../../assets/styles/utils';

.note-item {
  border-bottom: 1px solid var(--color-gray-25);
  padding: 24px 0;
  text-align: inherit;
  width: 100%;

  @include media-breakpoint-down('md') {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-25);
    border-radius: 8px;
    margin: 16px 0;
    padding: 24px;
  }

  &:last-child {
    border-bottom: 0;

    @include media-breakpoint-down('md') {
      border: 1px solid var(--color-gray-25);
    }
  }

  &__date {
    color: var(--color-gray-85);

    @include media-breakpoint-down('md') {
      order: 2;
    }
  }

  &__title {
    font-size: var(--font-lg);
    font-weight: var(--font-bold);
    margin: 0 0 4px;
  }

  &__content {
    color: var(--color-gray-85);
    font-size: var(--font-sm);
    font-weight: var(--font-bold);
  }
}

.note-modal {
  &__title {
    font-size: var(--font-xl);
    font-weight: var(--font-bold);
    margin: 0 0 4px;
  }

  &__content {
    font-size: var(--font-base);
    line-height: 1.3;
  }

  &__images {
    img {
      border-radius: 8px;
    }
  }
}

@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins/media-breakpoint';

.weather-hours {
  &__icon {
    background-color: var(--color-ob-green-50);
    border-radius: 50%;
  }

  &__item {
    background-color: var(--color-white);
    border-radius: 4px;
    margin-right: 6px;
    padding: 16px 5px;

    &:last-child {
      margin-right: 0;
    }

    &--active {
      background-color: var(--color-ob-green);
      color: var(--color-white);

      .weather-hours__icon {
        background-color: var(--color-ob-green-dark-50);
      }
    }
  }

  &__control {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__hour {
    font-size: var(--font-sm);
    font-weight: var(--font-bold);
  }

  &__temperature {
    font-size: var(--font-base);
    font-weight: var(--font-bold);
  }

  &__wind,
  &__wind-gust {
    font-size: var(--font-sm);
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.ob-tag {
  border-radius: 8px;
  font-weight: var(--font-bold);

  &--default {
    font-size: var(--font-sm);
    padding: 8px 32px;
  }

  &--small {
    font-size: var(--font-xs);
    padding: 8px 16px;
  }

  &--success {
    background-color: var(--color-green-30);
    color: var(--color-green-100);
  }

  &--warning {
    background-color: var(--color-orange-30);
    color: var(--color-orange-100);
  }

  &--error {
    background-color: var(--color-red-30);
    color: var(--color-red-100);
  }

  &--info {
    background-color: var(--color-blue-30);
    color: var(--color-blue-100);
  }
}

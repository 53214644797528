@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.radial-chart {
  position: relative;

  &__icon {
    left: 50%;
    position: absolute;
    transform: translate(-50%, -28%);
  }

  &__text {
    font-size: var(--font-lg);
    font-weight: var(--font-bold);
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);

    &--small {
      font-size: var(--font-sm);
    }

    &--blue {
      color: var(--color-blue-100);
    }
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.notes {
  &.container-fluid {
    margin: 0 -40px;
  }

  &__container {
    margin-left: 40px;
    margin-right: 20px;
  }

  &__title {
    font-size: var(--font-xl);
    font-weight: var(--font-bold);
    margin-bottom: 24px;
  }

  &__widget {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-25);
    border-radius: 8px;
    padding: 24px;
  }
}

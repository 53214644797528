@import '../assets/styles/variables';
@import '../assets/styles/mixins/media-breakpoint';

.layout {
  margin-left: 80px;

  &--mobile {
    margin-left: 0;
  }
}

@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins/media-breakpoint';

@import '../../../assets/styles/utils';

.graph-measure {
  &__daterangepicker {
    min-width: 250px;

    @include media-breakpoint-down('sm') {
      min-width: 100%;
    }
  }
}

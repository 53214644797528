@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.subheader {
  background-color: var(--color-white);
  height: 63px;
  padding: 0 40px;

  &__select {
    margin: 0 42px 0 0;
    width: 240px;
  }
}

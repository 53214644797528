@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.map {
  height: 350px;
  width: 100%;

  .leaflet-container {
    border-radius: 8px;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}

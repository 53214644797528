@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.btn {
  align-items: center;
  border-radius: 8px;
  display: inline-block;
  flex-direction: row;
  font-weight: var(--font-bold);
  justify-content: center;

  &--big {
    font-size: 14px;
    padding: 15px 43px;
  }

  &--default {
    font-size: 12px;
    padding: 13px 37px;
  }

  &--small {
    font-size: 12px;
    padding: 8px 16px;
  }

  &--block {
    width: 100%;
  }

  &--disabled {
    &[disabled] {
      background-color: var(--color-gray-25) !important;
      color: var(--color-gray-45) !important;
      cursor: not-allowed;
    }
  }

  &--primary {
    background-color: var(--color-ob-green);
    color: var(--color-white);
  }

  &--ghost {
    background-color: transparent;
    border: 1px solid var(--color-gray-45);
    color: var(--color-ob-green);
  }

  &--secondary {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-45);
    color: var(--color-ob-green);
  }

  &--link {
    background-color: transparent;
    border: 0;
    color: var(--color-ob-green);
  }

  &--dark {
    color: var(--color-white);
  }
}

* {
  box-sizing: border-box;
  font-family: var(--font-family);
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  padding: 0;
}

body {
  background-color: var(--color-gray-10);
  margin: 0;
  padding: 0;
}

hr {
  border: 0;
  border-bottom: 1px solid var(--color-gray-45);
  height: 1px;
}

*:focus-visible {
  outline: 0;
}

::-webkit-scrollbar-track {
  background-color: var(--color-gray-10);
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 25%);
}

::-webkit-scrollbar {
  background-color: #f5f5f5;
  border-radius: 10px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-85);
  border-radius: 10px;
}

.img-responsive {
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

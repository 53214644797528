@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.collapse {
  background-color: var(--color-gray-10);
  border: 1px solid var(--color-gray-25);
  border-radius: 8px;
  cursor: pointer;
  padding: 7px 12px;
  width: 100%;

  &--white {
    background-color: var(--color-white);
  }

  &__header {
    padding: 8px 0;
    text-align: left;
  }

  &__title {
    font-size: var(--font-lg);
    font-weight: var(--font-bold);
  }

  &__label {
    font-size: var(--font-base);
  }

  &__content {
    font-size: var(--font-sm);
    padding: 8px 0;
    text-align: left;
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

@import '../../assets/styles/utils';

.culture-phases--mobile {
  .phases-list {
    display: flex;
    flex-flow: row;
    overflow-x: scroll;
    width: 100%;

    &::-webkit-scrollbar {
      border: 1px solid #d5d5d5;
      box-shadow: inset 0 0 0 rgb(0 0 0 / 0%);
      height: 4px;
    }

    .phases-block {
      flex: 1;
      font-size: 22px;
      height: 150px;
      max-width: 200px;
      min-width: 200px;
      padding: 20px;
      position: relative;
      text-align: center;

      &:first-child::before {
        border-bottom: 0;
      }

      &::before {
        border-bottom: 2px solid;
        content: '';
        left: -79px;
        position: absolute;
        top: 39%;
        width: 158px;
      }

      &:last-child {
        margin: 0;
      }

      .phase-item {
        position: relative;
        text-align: center;

        &__icon {
          background-color: var(--color-ob-green);
          border-radius: 50%;
          color: var(--color-white);
          height: 42px;
          position: relative;
          width: 42px;
        }

        &__title {
          color: var(--color-ob-green);
          font-size: var(--font-sm);
          height: 20px;
          margin: 8px 0;
        }
      }

      &__line {
        align-items: center !important;
        border: 1px solid var(--color-ob-green-50);
        content: '';
        height: 0;
        margin-left: -1px;
        position: absolute;
        right: -22px;
        top: 20px;
        width: 122px;
        z-index: 1;

        @include media-breakpoint-down('sm') {
          left: -98px;
          width: 158px;
        }
      }

      &--active {
        &::before {
          border-color: var(--color-ob-green);
        }
      }

      &--done {
        &::before {
          border-color: var(--color-ob-green-50);
        }

        .phase-item {
          &__icon {
            background-color: var(--color-ob-green-50);
          }

          &__title {
            color: var(--color-ob-green-50);
          }
        }
      }

      &--idle {
        &::before {
          border-color: var(--color-gray-25);
        }

        .phase-item {
          &__icon {
            background-color: var(--color-gray-25);
          }

          &__title {
            color: var(--color-gray-45);
          }
        }

        .phases-block__line {
          border: 1px solid var(--color-gray-25);
        }
      }
    }
  }

  .phases-status {
    margin: 16px 0 0;

    &__title {
      font-size: var(--font-base);
    }

    &__icon {
      background-color: var(--color-ob-green);
      border-radius: 50%;
      color: var(--color-white);
      height: 28px;
      width: 28px;
    }
  }
}

.culture-phases {
  --culture-icon-size: 35px;

  &__icon {
    background-color: var(--color-ob-green);
    border-radius: 50px;
    color: var(--color-white);
    height: var(--culture-icon-size);
    width: var(--culture-icon-size);
  }

  &__container {
    @include media-breakpoint-up('md') {
      height: 440px;
      overflow: auto;
    }
  }

  .phases-list {
    .phases-block {
      width: 100%;

      .phase-item {
        &__icon {
          background-color: var(--color-ob-green);
          border-radius: 50%;
          color: var(--color-white);
          height: var(--culture-icon-size);
          width: var(--culture-icon-size);
        }

        &__title {
          color: var(--color-ob-green);
          margin-left: 8px;
          width: 280px;

          &--active {
            font-weight: var(--font-bold);
          }
        }
      }

      &__line {
        align-items: center !important;
        border: 1px solid var(--color-ob-green-50);
        content: '';
        height: 70px;
        left: 18px;
        margin-left: -1px;
        position: relative;
        width: 0;
        z-index: 1;
      }

      &--done {
        .phase-item {
          &__icon {
            background-color: var(--color-ob-green-50);
          }

          &__title {
            color: var(--color-ob-green-50);
          }
        }
      }

      &--idle {
        .phase-item {
          &__icon {
            background-color: var(--color-gray-25);
          }

          &__title {
            color: var(--color-gray-45);
          }
        }

        .phases-block__line {
          border: 1px solid var(--color-gray-25);
        }
      }
    }
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

@import '../../assets/styles/utils';

.login {
  .login__loading {
    background: linear-gradient(167.84deg, var(--color-ob-green) -11.74%, var(--color-ob-green-dark) 99.55%);
    color: var(--color-white);
    height: 100vh;
    position: absolute;
    width: 100%;
    z-index: 1000;
  }

  .login-mobile {
    background: linear-gradient(167.84deg, var(--color-ob-green) -11.74%, var(--color-ob-green-dark) 99.55%);
    background-position: 30% 180%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vh;
    position: relative;

    &__header {
      color: var(--color-white);
      font-size: var(--font-2xl);
      font-weight: var(--font-bold);
      height: 80%;
    }

    &__image {
      height: 100vh;
      position: fixed;
      top: 0;
      width: 100%;
    }

    &__title {
      color: var(--color-ob-green);
      font-size: var(--font-2xl);
      font-weight: var(--font-bold);
    }

    &__forget {
      color: var(--color-ob-green);
      font-size: var(--font-base);
      font-weight: var(--font-bold);
    }

    &__block {
      bottom: 0;
      position: fixed;
      width: 100%;
    }

    &__container {
      background-color: var(--color-white);
      border-radius: 60px 60px 0 0;
      margin: -20px 0 0;
      padding: 45px 24px;
      width: 100%;
    }

    &__footer {
      background-color: var(--color-gray-10);
      padding: 24px;

      &-questions {
        font-size: var(--font-base);
        font-weight: var(--font-bold);
      }

      &-contact {
        color: var(--color-ob-green);
        font-size: var(--font-lg);
        font-weight: var(--font-bold);
      }
    }
  }

  .login-desktop {
    height: 100vh;

    &__container {
      padding: 56px 80px;
    }

    &__header {
      color: var(--color-ob-green-dark);
      font-size: var(--font-xl);
      font-weight: var(--font-bold);
    }

    &__form {
      height: 90%;
      position: relative;
      z-index: 10;

      &-subtitle {
        font-size: var(--font-lg);
      }
    }

    &__wallpaper {
      background: linear-gradient(110.03deg, rgb(219 205 0 / 70%) -9.51%, rgb(99 179 46 / 70%) 100.98%);
      height: 100%;
      position: relative;
      z-index: 1;
    }

    .wallpaper-img {
      bottom: 0;
      position: absolute;
      right: 0;
      z-index: 1;

      @include media-breakpoint-down('xl') {
        width: 80%;
      }
    }

    &__footer {
      &-questions {
        font-size: var(--font-base);
        font-weight: var(--font-bold);
      }

      &-contact {
        color: var(--color-ob-green);
        font-size: var(--font-lg);
        font-weight: var(--font-bold);
      }
    }
  }
}

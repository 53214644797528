@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.alerts {
  .container-fluid {
    margin: 0 -40px;
  }

  &__subheader {
    background-color: var(--color-white);
    padding: 0 40px;
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.input-number {
  border: 1px solid var(--color-gray-45);
  border-radius: 8px;
  color: var(--color-black);
  font-size: var(--font-base);
  max-height: 38px;
  position: relative;
  width: 100%;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    appearance: textfield;
  }

  input {
    background-color: inherit;
    border: 0;
    height: fit-content;
    padding: 12px;
    width: 95%;
  }

  &__arrows {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__arrow {
    width: 24px;
  }

  &__arrow--up {
    border-bottom: 1px solid var(--color-gray-45);
    border-left: 1px solid var(--color-gray-45);
  }

  &__arrow--down {
    border-left: 1px solid var(--color-gray-45);
  }

  &__arrow--left {
    border-right: 1px solid var(--color-gray-45);
    width: 50px;
  }

  &__arrow--right {
    border-left: 1px solid var(--color-gray-45);
    width: 50px;
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.drop-file {
  &__zone {
    background-color: var(--color-gray-10);
    border: 2px dashed var(--color-gray-45);
    border-radius: 8px;
    color: var(--color-gray-85);
    font-size: var(--font-sm);
    padding: 42px 16px;
    position: relative;

    .drop-file__input {
      cursor: pointer;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__error {
    color: var(--color-red-100);
    font-size: var(--font-base);
  }
}

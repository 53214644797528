@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.dropdown-menu {
  &__container {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    width: fit-content;
  }

  &__list {
    background-color: var(--color-white);
    border-radius: 4px;
    display: none;
    filter: var(--drop-shadow);
    position: absolute;
    top: 30px;
    width: max-content;

    &--open {
      display: block;
    }

    &--right {
      right: -11px;
    }

    &--left {
      left: 0;
    }
  }

  &__item {
    border-bottom: 1px solid var(--color-gray-50);
    font-size: var(--font-base);
    padding: 14px;

    &:last-child {
      border: 0;
    }
  }
}

@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins/media-breakpoint';

.weather-slider {
  position: relative;

  .weather-hours {
    &__item {
      border: 1px solid var(--color-gray-25);

      &--active {
        border: 1px solid var(--color-ob-green);
      }
    }
  }

  &__control {
    background-color: var(--color-ob-green);
    border: 2px solid var(--color-white);
    border-radius: 50%;
    bottom: 50%;
    color: var(--color-white);
    height: 34px;
    margin: auto;
    position: absolute;
    top: 50%;
    width: 34px;

    &--left {
      left: -16px;
    }

    &--right {
      right: -16px;
    }
  }
}

@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins/media-breakpoint';

@import '../../../assets/styles/utils';

.weather {
  background-color: var(--color-ob-green);
  border-radius: 12px;
  color: var(--color-white);
  min-height: 274px;
  padding: 24px;

  .weather-header {
    width: 180px;

    &__button {
      font-size: var(--font-sm);
      font-weight: var(--font-bold);
      text-decoration: underline;
    }
  }

  .weather-body {
    &__temperature {
      font-size: var(--font-4xl);
      font-weight: var(--font-bold);
      margin-bottom: 2px;

      @include media-breakpoint-down('xs') {
        font-size: var(--font-3xl);
      }
    }

    &__icon {
      margin: 0 0 0 -16px;
    }

    &__condition {
      font-size: var(--font-lg);
      font-weight: var(--font-bold);
    }
  }

  .weather-footer {
    font-size: var(--font-sm);
  }
}

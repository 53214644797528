.form {
  .form-control {
    margin: 16px 0;

    &__label {
      display: block;
    }

    &__input {
      display: block;
      width: 100%;
    }

    &__error {
      color: var(--color-red-100);
    }
  }
}

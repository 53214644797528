@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.dashboard {
  &__cycle {
    @include media-breakpoint-up('md') {
      height: 600px;
    }
  }

  &__subheader {
    margin: 0 -40px;
  }

  &__title {
    font-size: var(--font-lg);
    font-weight: var(--font-bold);
  }

  &__widget {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-25);
    border-radius: 8px;
    padding: 24px;

    &-title {
      font-size: var(--font-xl);
      font-weight: var(--font-bold);
    }

    &--green {
      background-color: var(--color-ob-green);
      border: 1px solid var(--color-ob-green);
    }
  }

  &__shadepanel-title {
    font-size: var(--font-lg);
    font-weight: var(--font-bold);
    padding: 16px 0;
  }
}

.shadepanel-data {
  &__widget {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-25);
    border-radius: 8px;
    padding: 12px;
  }
}

.grid-masonary {
  &__item {
    margin: 16px 4px;
  }
}

.add-sensor {
  &__controls {
    color: var(--color-blue-100);

    &--trash {
      color: var(--color-red-100);
    }
  }
}

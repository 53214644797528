@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.sidebar {
  background-color: var(--color-white);
  border-right: 1px solid var(--color-gray-25);
  height: 100vh;
  padding: 8px;
  position: fixed;

  &--show {
    width: 250px;
  }

  &__logo {
    color: var(--color-ob-green-dark);
    font-size: var(--font-xl);
    font-weight: var(--font-bold);
  }

  &__toogle {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-25);
    border-radius: 50%;
    color: var(--color-ob-green);
    height: 46px;
    position: absolute;
    right: -23px;
    top: 62px;
    width: 46px;
    z-index: 3;
  }

  .nav {
    margin-top: 96px;

    &__title {
      color: var(--color-dark-gray);
      font-size: var(--font-lg);
      font-weight: var(--font-bold);
      margin: 12px;
      padding: 8px;

      &--active {
        background-color: var(--color-ob-green-10);
        border-radius: 6px;
        color: var(--color-ob-green);
      }

      &--help {
        color: var(--color-ob-green);
      }
    }

    &__separator {
      border-bottom: 1px solid var(--color-gray-25);
      padding: 8px;
      padding: 0 33px;
    }

    &--help {
      bottom: 18px;
      position: absolute;
    }
  }
}

@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins/media-breakpoint';

.header {
  background-color: var(--color-white);
  z-index: 2;

  &--mobile {
    border: 0;
    filter: var(--drop-shadow-lg);
  }

  &__logo {
    color: var(--color-ob-green-dark);
    font-size: var(--font-xl);
    font-weight: var(--font-bold);
    margin: 8px 0;
  }

  .nav-right {
    &__item {
      background-color: var(--color-gray-10);
      border-radius: 8px;
      padding: 9px;
    }
  }
}

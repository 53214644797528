@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.input-control {
  &--focused {
    // label {
    //   color: var(--color-ob-green);
    // }
  }

  &--error {
    label {
      color: var(--color-red-100);
    }
  }

  label {
    cursor: pointer;
    display: block;
    font-size: var(--font-base);
    margin-bottom: 5px;
  }

  .input-fake {
    position: relative;
    width: 100%;

    &__icon {
      position: absolute;
      right: 5px;
      top: 7px;

      &--error {
        color: var(--color-red-100);
      }
    }
  }

  &__input {
    border: 1px solid var(--color-gray-45);
    border-radius: 8px;
    color: var(--color-black);
    font-size: var(--font-base);
    max-height: 38px;
    padding: 12px;
    position: relative;
    width: 100%;

    input {
      background-color: unset;
      border: 0;
      width: 95%;

      &:focus,
      &:focus-visible {
        border: 2px solid var(--color-ob-green);
        margin: -1px;
        outline: 0;
      }
    }

    .input-control--error & {
      border: 2px solid var(--color-red-100 !important);
      margin: -1px;
    }

    &:hover {
      border: 2px solid var(--color-gray-45);
      margin: -1px;
    }

    &:focus,
    &:focus-visible {
      border: 2px solid var(--color-ob-green);
      margin: -1px;
      outline: 0;
    }
  }

  &__icon {
    line-height: 0;
    margin-left: 8px;
  }

  &__hint {
    color: var(--color-dark-gray);
    font-size: var(--font-sm);
    margin-top: 5px;

    &--error {
      color: var(--color-red-100);
    }
  }
}

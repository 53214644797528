@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

@import '../../assets/styles/utils';

.alert-create {
  background-color: var(--color-white);
  min-height: calc(100vh - (87px + 63px));
  padding: 24px;

  &__error {
    color: var(--color-red-100);
    font-size: var(--font-sm);
    margin-top: 5px;
  }

  .criteria {
    border: 1px solid var(--color-gray-25);
    border-radius: 4px;
    padding: 24px;

    &__remove {
      color: var(--color-red-100);
    }
  }

  @include media-breakpoint-down('sm') {
    border: 1px solid var(--color-gray-25);
    border-radius: 8px;
    min-height: auto;
  }
}

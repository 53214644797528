@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

@import '../../assets/styles/variables';

.alert {
  border-radius: 8px;
  padding: 20px;

  &__title {
    font-size: var(--font-base);
    font-weight: var(--font-bold);
    margin-bottom: 12px;
  }

  &__label {
    font-size: var(--font-sm);
  }

  &--success {
    background-color: var(--color-green-30);
    color: var(--color-green-100);
  }

  &--warning {
    background-color: var(--color-orange-30);
    color: var(--color-orange-100);
  }

  &--error {
    background-color: var(--color-red-30);
    color: var(--color-red-100);
  }

  &--info {
    background-color: var(--color-blue-30);
    color: var(--color-blue-100);
  }
}

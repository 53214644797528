@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

button {
  cursor: pointer;
}

.datawidget {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-25);
  border-radius: 8px;
  padding: 12px 16px;

  &__title {
    margin: 0 0 5px;
  }

  &__label {
    color: var(--color-gray-400);
    font-size: var(--font-base);
    font-weight: var(--font-bold);
  }

  &__icon {
    border-radius: 50%;
    height: 36px;
    margin: 0 8px 0 0;
    width: 36px;

    &--green {
      background-color: var(--color-green-30);
      color: var(--color-green-50);
    }

    &--yellow {
      background-color: var(--color-yellow-30);
      color: var(--color-yellow-50);
    }

    &--blue {
      background-color: var(--color-blue-30);
      color: var(--color-blue-50);
    }

    &--red {
      background-color: var(--color-red-30);
      color: var(--color-red-50);
    }
  }

  &__header {
    margin-bottom: 24px;
  }

  // &__more {
  //   cursor: pointer;
  //   position: absolute;
  //   right: 2px;
  //   top: 0;
  //   z-index: 10000;
  // }

  .datawidget-sensor {
    &__label {
      font-size: var(--font-base);
      margin-bottom: 16px;
    }

    &__value {
      font-size: var(--font-2xl);
      font-weight: var(--font-bold);
      margin-top: 5px;
    }
  }

  .datawidget-circle {
    &__value {
      font-size: var(--font-lg);
      font-weight: var(--font-bold);
    }
  }

  &__empty {
    font-size: var(--font-base);
    min-height: 57px;

    &--position {
      min-height: 166px;
    }
  }
}

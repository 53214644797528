@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.pagination {
  &__button {
    border: 1px solid var(--color-gray-45);
    border-radius: 8px;
    height: 32px;
    margin: 0 4px;
    min-width: 32px;

    &--separator {
      cursor: default;
    }

    &--active {
      background-color: var(--color-blue-100);
      border-color: var(--color-blue-100);
      color: var(--color-white);
    }
  }

  &--default {
    .pagination__button {
      background-color: var(--color-white);

      &--active {
        background-color: var(--color-blue-100);
        border-color: var(--color-blue-100);
        color: var(--color-white);
      }
    }
  }

  &--ghost {
    .pagination__button {
      background-color: transparent;

      &--active {
        background-color: var(--color-blue-100);
        border-color: var(--color-blue-100);
        color: var(--color-white);
      }
    }
  }
}

@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins/media-breakpoint';

.data-radial {
  &__only-text {
    font-size: var(--font-2xl);
  }

  &__label {
    font-size: var(--font-xl);
  }

  .radial-chart__text {
    font-size: var(--font-2xl);
  }
}

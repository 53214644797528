@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.dropdown {
  &__label {
    cursor: pointer;
    display: block;
    font-size: var(--font-base);
    margin-bottom: 5px;
  }

  &__container {
    border: 1px solid var(--color-gray-45);
    border-radius: 8px;
    fill: var(--color-black);
    font-weight: var(--font-bold);
    min-height: 38px;
    padding: 8px 16px;
    text-align: left;
    width: 100%;

    &--block {
      width: 100%;
    }

    svg {
      margin-left: 8px;
    }

    &--open {
      background-color: var(--color-white);
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-color: var(--color-ob-green);
    }
  }

  .dropdown-list {
    background-color: var(--color-white);
    border: 1px solid var(--color-ob-green);
    border-bottom: 1px solid var(--color-ob-green);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 1px solid var(--color-ob-green);
    border-right: 1px solid var(--color-ob-green);
    border-top: 0;
    color: var(--color-gray-85);
    font-weight: var(--font-bold);
    padding: 8px 16px 16px;
    position: absolute;
    width: 100%;
    z-index: 1;

    &--block {
      width: 100%;
    }

    &__item {
      border-radius: 8px;
      cursor: pointer;
      padding: 12px;
      text-align: left;

      &--active {
        background-color: var(--color-ob-green);
      }

      &:hover {
        background-color: var(--color-ob-green);
        color: var(--color-white);
      }
    }
  }

  &--dark {
    .dropdown__container {
      background-color: var(--color-ob-green);
      border-color: var(--color-white);
      color: var(--color-white);
    }

    .dropdown-list {
      background-color: var(--color-ob-green);
      border-color: var(--color-white);
      color: var(--color-white);
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .dropdown-list__item {
      &:hover {
        background-color: var(--color-white);
        color: var(--color-ob-green);
      }
    }
  }

  &__input-fake {
    position: relative;
  }

  &__clear {
    color: var(--color-gray-400);
    position: absolute;
    right: 42px;
    top: 11px;
    z-index: 1000;

    &:hover {
      display: block;
    }
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;

  &__item {
    background-color: #ebebeb;
    border-radius: 4px;
    width: 100%;

    &--animation {
      animation: skeleton-animation 1.4s ease infinite;
      background: linear-gradient(90deg, var(--base-color), var(--highlight-color), var(--base-color));
      background-size: 400% 100%;
    }
  }
}

@keyframes skeleton-animation {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.switch {
  display: flex;
  height: 24px;
  position: relative;
  width: 48px;

  .switch-slider {
    background-color: var(--color-gray-45);
    border-radius: 34px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;

    &::before {
      background-color: var(--color-white);
      border-radius: 50%;
      bottom: 3px;
      content: '';
      height: 18px;
      left: 6px;
      position: absolute;
      transition: 0.4s;
      width: 18px;
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  input:checked + .switch-slider {
    background-color: var(--color-ob-green);
  }

  input:focus + .switch-slider {
    box-shadow: 0 0 1px var(--color-ob-green);
  }

  input:checked + .switch-slider::before {
    transform: translateX(20px);
  }

  input:disabled + .ob-switch-slider {
    background: var(--color-blue-30);
    cursor: not-allowed;
  }
}

:root {
  // Font Family
  --font-family: 'Typ1451';

  // Font Size
  --font-xs: 10px;
  --font-sm: 12px;
  --font-base: 14px;
  --font-lg: 16px;
  --font-xl: 20px;
  --font-2xl: 24px;
  --font-3xl: 36px;
  --font-4xl: 46px;

  // Font Weight
  --font-bold: 700;
  --font-light: 400;

  /// Colors
  --color-ob-green-dark: #1d7b33;
  --color-ob-green: #63b32e;
  --color-ob-yellow: #dbcd00;
  --color-ob-green-dark-50: rgb(29 123 51 / 50%);
  --color-ob-green-50: rgb(101 178 46 / 50%);
  --color-ob-yellow-50: rgb(219 205 0 / 50%);
  --color-ob-green-10: hsl(95deg 59% 44% / 10%);

  // Alerts
  --color-blue-100: #0084f4;
  --color-blue-50: #66b5f8;
  --color-blue-30: #d5e9fa;
  --color-orange-100: #ff7e32;
  --color-orange-50: #ffc7a6;
  --color-orange-30: #ffe8da;
  --color-yellow-50: #ffcf5c;
  --color-yellow-30: #fffaef;
  --color-red-100: #ff6b6b;
  --color-red-50: #f9c7c7;
  --color-red-30: #ffe7e7;
  --color-green-100: #00c48c;
  --color-green-50: #7ddfc3;
  --color-green-30: #e8fef8;

  // Neutral
  --color-white: #fff;
  --color-black: hsl(60deg 4% 14% / 100%);
  --color-dark-gray: hsl(229deg 8% 32% / 100%);
  --color-gray-85: hsl(232deg 7% 52% / 100%);
  --color-dark-75: hsl(229deg 8% 32% / 20%);
  --color-gray-50: hsl(0deg 0% 92% / 50%);
  --color-gray-45: hsl(232deg 16% 82% / 100%);
  --color-gray-25: hsl(231deg 33% 96% / 100%);
  --color-gray-10: hsl(240deg 67% 99% / 100%);

  // Gray
  --color-gray-100: rgb(243 244 246);
  --color-gray-200: rgb(229 231 235);
  --color-gray-300: rgb(209 213 219);
  --color-gray-400: rgb(156 163 175);
  --color-gray-500: rgb(107 114 128);
  --color-gray-600: rgb(75 85 99);
  --color-gray-700: rgb(55 65 81);
  --color-gray-800: rgb(31 41 55);
  --color-gray-900: rgb(17 24 39);

  // Drop Shadow
  --drop-shadow-sm: drop-shadow(0 1px 1px rgb(0 0 0 / 5%));
  --drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 10%)) drop-shadow(0 1px 1px rgb(0 0 0 / 6%));
  --drop-shadow-md: drop-shadow(0 4px 3px rgb(0 0 0 / 7%)) drop-shadow(0 2px 2px rgb(0 0 0 / 6%));
  --drop-shadow-lg: drop-shadow(0 10px 8px rgb(0 0 0 / 4%)) drop-shadow(0 4px 3px rgb(0 0 0 / 10%));
  --drop-shadow-xl: drop-shadow(0 20px 13px rgb(0 0 0 / 3%)) drop-shadow(0 8px 5px rgb(0 0 0 / 8%));
  --drop-shadow-2xl: drop-shadow(0 25px 25px rgb(0 0 0 / 15%));
  --drop-shadow-none: drop-shadow(0 0 #0000);

  // Box Shadow
  --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 5%);
  --shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%);
  --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 10%);
  --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%);
  --shadow-xl: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 8px 10px -6px rgb(0 0 0 / 10%);
  --shadow-2xl: 0 25px 50px -12px rgb(0 0 0 / 25%);
  --shadow-inner: inset 0 2px 4px 0 rgb(0 0 0 / 5%);
  --shadow-none: 0 0 #0000;
}

// Breakpoints
$grid-breakpoints: (
  xs: 320px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1536px
) !default;

// Spacings
$spacings: (
  0: 0,
  1: 8px,
  2: 16px,
  3: 24px,
  4: 32px,
  5: 40px
) !default;

@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins/media-breakpoint';

.distances {
  --height: 45px;
  --radius: 50px;
  --border: 2px solid var(--color-blue-100);
  --width-line: 10px;

  &__value {
    font-size: var(--font-base);
    height: var(--height);
    position: relative;
    width: 100%;

    &--distance {
      background-color: var(--color-gray-85);
      content: '';
      height: 1px;
      position: absolute;
      right: -21px;
      top: 50%;
      width: 28px;
    }
  }

  &__line {
    background-color: var(--color-blue-30);
    border-left: var(--border);
    border-right: var(--border);
    content: '';
    height: var(--height);
    width: var(--width-line);

    .line-inside {
      background-color: var(--color-gray-85);
      content: '';
      height: 2px;
      margin-bottom: 2px;
      width: 10px;
    }

    &--first {
      border-top: var(--border);
      border-top-left-radius: var(--radius);
      border-top-right-radius: var(--radius);
    }

    &--last {
      border-bottom: var(--border);
      border-bottom-left-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.tabs {
  &--default {
    .tab-options {
      &__item {
        font-weight: var(--font-bold);
        padding: 24px;

        &--active {
          border-bottom: 2px solid var(--color-ob-green);
          color: var(--color-ob-green);
        }
      }
    }
  }

  &--pills {
    background-color: var(--color-white);
    border: 1px solid var(--color-ob-green-50);
    border-radius: 8px;
    padding: 8px 16px;

    .tab-options {
      &__item {
        font-size: var(--font-sm);
        line-height: 18px;
        margin-right: 4px;
        padding: 8px 16px;
        text-align: center;

        &:last-child {
          margin-right: 0;
        }

        &--active {
          background-color: var(--color-ob-green);
          border-radius: 8px;
          color: var(--color-white);
          font-weight: var(--font-bold);
        }
      }
    }
  }
}

@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins/media-breakpoint';

.header {
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-gray-25);
  padding: 24px;

  &__title {
    font-size: var(--font-xl);
    font-weight: var(--font-bold);
  }

  &--sidebar {
    margin-left: 170px !important;
  }
}

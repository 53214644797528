@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.toast {
  border-left: 2.5px solid transparent;
  padding-left: 10px;

  &--success {
    border-left-color: var(--color-green-100);
  }

  &--error {
    border-left-color: var(--color-red-100);
  }

  &__title {
    font-size: var(--font-sm);
    font-weight: var(--font-bold);
  }

  &__description {
    font-size: var(--font-sm);
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.spinner {
  --time-spinner: 1.6s;

  animation: spinner var(--time-spinner) infinite linear;
  animation-delay: 0.25s;
  background-color: var(--color-ob-green-50);
  border-radius: 5px;
  box-shadow: 9999px 0 0 -5px var(--color-ob-green-50);
  color: var(--color-ob-green-50);
  height: 10px;
  left: -9999px;
  position: relative;
  width: 10px;

  &::before {
    animation: spinner-before var(--time-spinner) infinite linear;
    animation-delay: 0s;
    background-color: var(--color-ob-green-50);
    border-radius: 5px;
    box-shadow: 9984px 0 0 -5px var(--color-ob-green-50);
    color: var(--color-ob-green-50);
    content: '';
    display: inline-block;
    height: 10px;
    margin: 0 -5px;
    position: absolute;
    top: 0;
    width: 10px;
  }

  &::after {
    animation: spinner-after var(--time-spinner) infinite linear;
    animation-delay: 0.5s;
    background-color: var(--color-ob-green-50);
    border-radius: 5px;
    box-shadow: 10014px 0 0 -5px var(--color-ob-green-50);
    color: var(--color-ob-green-50);
    content: '';
    display: inline-block;
    height: 10px;
    margin: 0 5px;
    position: absolute;
    top: 0;
    width: 10px;
  }
}

@keyframes spinner-before {
  0% {
    box-shadow: 9984px 0 0 -5px var(--color-ob-green-50);
  }

  30% {
    box-shadow: 9984px 0 0 2px var(--color-ob-green-50);
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px var(--color-ob-green-50);
  }
}

@keyframes spinner {
  0% {
    box-shadow: 9999px 0 0 -5px var(--color-ob-green-50);
  }

  30% {
    box-shadow: 9999px 0 0 2px var(--color-ob-green-50);
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px var(--color-ob-green-50);
  }
}

@keyframes spinner-after {
  0% {
    box-shadow: 10014px 0 0 -5px var(--color-ob-green-50);
  }

  30% {
    box-shadow: 10014px 0 0 2px var(--color-ob-green-50);
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px var(--color-ob-green-50);
  }
}

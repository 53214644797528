@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.select {
  cursor: pointer;
  position: relative;
  user-select: none;

  .select-input {
    border: 1px solid var(--color-gray-45);
    border-radius: 8px;
    color: var(--color-gray-85);
    display: block;
    padding: 8px;
    position: relative;
    width: 100%;

    &__icon {
      bottom: 0;
      line-height: 0;
      margin: auto;
      position: absolute;
      right: 4px;
      top: 4px;
    }
  }

  .select-dropdown {
    background-color: var(--color-white);
    box-shadow: var(--shadow-md);
    margin: 3px 0 0;
    position: absolute;
    width: 100%;

    &__option {
      padding: 8px;

      &:hover {
        background-color: var(--color-gray-10);
      }
    }
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

@import '../../assets/styles/utils';

.shadepanel-data {
  &__subheader {
    margin: 0 -40px;
  }

  &__title {
    font-size: var(--font-xl);
    font-weight: var(--font-bold);
  }

  .controls {
    &__item {
      margin: 0 16px 0 0;

      &--dropdown {
        min-width: 180px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  .data-featured {
    &__item {
      margin: 0;
    }
  }

  .shadepanel-data__widget {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-25);
    border-radius: 8px;
    padding: 24px;
  }

  .data-widget {
    height: 450px;
    margin: 0 8px 16px;

    &__title {
      font-size: var(--font-xl);
      font-weight: var(--font-bold);

      @include media-breakpoint-down('sm') {
        font-size: var(--font-base);
      }
    }
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.account {
  .container-fluid {
    margin: 0 -40px;
  }

  &__card-button {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-25);
    border-radius: 12px;
    padding: 24px;

    .label-card {
      font-weight: var(--font-bold);
    }

    .icon-card {
      color: var(--color-ob-green);
    }
  }

  .dropdown-fake {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-25);
    border-radius: 8px;
    color: var(--color-ob-green);
    font-weight: var(--font-bold);
    padding: 12px 16px;
    width: 100%;
  }

  &__back {
    color: var(--color-ob-green);
    font-size: var(--font-base);
    font-weight: var(--font-bold);
  }
}

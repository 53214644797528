@import '../assets/styles/variables';
@import '../assets/styles/mixins/media-breakpoint';

@import '../assets/styles/mixins/media-breakpoint';

.container {
  margin-bottom: 124px;
  width: 100%;

  &--sidebar {
    padding-left: 210px !important;
  }

  @include media-breakpoint-down('sm') {
    padding: 0 24px;
  }

  @include media-breakpoint-up('sm') {
    margin-bottom: 0;
    padding: 0 40px;
  }
}

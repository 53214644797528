@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.sidebar-mobile {
  &__body {
    background-color: var(--color-white);
    height: 100vh;
    left: 0;
    top: 0;
    width: 300px;
    z-index: 1000;

    .logo {
      font-size: var(--font-xl);
    }

    .header {
      &__arrow {
        background-color: var(--color-ob-green);
        border-radius: 50%;
        color: var(--color-white);
        height: 36px;
        width: 36px;
      }
    }

    .content {
      background-color: var(--color-gray-10);
      height: calc(100vh - 116px);

      hr {
        border: 1px solid var(--color-gray-45);
      }

      .nav {
        color: var(--color-gray-85);
        font-weight: var(--font-bold);

        &__separator {
          border-bottom: 2px solid var(--color-gray-45);
          margin: 16px 0;
        }

        &__item {
          align-items: center;
          display: flex;

          // margin: 16px 0;
          padding: 8px 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &__title {
          align-items: center;
          color: var(--color-dark-gray);
          display: flex;
          font-size: var(--font-lg);
          font-weight: var(--font-bold);
          margin: 8px 24px;
          padding: 8px 16px;

          &--active {
            background-color: var(--color-ob-green-10);
            border-radius: 8px;
            color: var(--color-ob-green);
            fill: var(--color-ob-green);
          }

          &--help {
            color: var(--color-ob-green);
            fill: var(--color-ob-green);
          }
        }
      }

      .nav-icon-fake {
        height: 24px;
        width: 24px;
      }

      .nav-block {
        &--help {
          bottom: 0;
          position: absolute;
        }
      }
    }
  }

  &__header {
    box-shadow: var(--shadow-md);
    color: var(--color-ob-green-dark);
    font-size: var(--font-lg);
    font-weight: var(--font-bold);
    position: relative;
    z-index: 2000;
  }

  .siderbar-overlay {
    background-color: hsl(0deg 0% 0% / 40%);
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins/media-breakpoint';

.alert-list {
  .alert-item {
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 24px 16px;

    &__title,
    &__date {
      font-size: var(--font-base);
      font-weight: var(--font-bold);
    }

    &__card {
      background-color: var(--color-white);
      border: 1px solid var(--color-white);
      border-radius: 8px;
      font-size: var(--font-base);
      padding: 24px;
    }

    &--danger {
      background-color: var(--color-red-30);

      .alert-item__title {
        color: var(--color-red-100);
      }

      .alert-item__card {
        border-color: var(--color-red-100);
      }
    }

    &--warning {
      background-color: var(--color-orange-30);

      .alert-item__title {
        color: var(--color-orange-100);
      }

      .alert-item__card {
        border-color: var(--color-orange-100);
      }
    }
  }
}

@font-face {
  font-family: 'Typ1451';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/typ1451/Typ1451-Medium.woff2') format('woff2'), url('../fonts/typ1451/Typ1451-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Typ1451';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/typ1451/Typ1451-Normal.woff2') format('woff2'), url('../fonts/typ1451/Typ1451-Normal.woff') format('woff');
}

@font-face {
  font-family: 'Typ1451';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/typ1451/Typ1451-Bold.woff2') format('woff2'), url('../fonts/typ1451/Typ1451-Bold.woff') format('woff');
}
